import React, { useRef } from 'react';
import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl';

const Language = () => {
  const selectLanguage = useRef(null);
  const languageName = {
    en: 'EN',
    es: 'ES',
  };

  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <>
            <label htmlFor="languages" className="sr-only">
              Languages
            </label>
            <select
              ref={selectLanguage}
              id="Languages"
              name="Languages"
              defaultValue={currentLocale}
              onChange={() => changeLocale(selectLanguage.current.value)}
              className="focus:ring-primary-navy border-none py-0 pl-2 pr-7 text-gray-500 rounded-md"
            >
              {languages.map((language, index) => (
                <option value={language} key={index}>
                  {languageName[language]}
                </option>
              ))}
            </select>
          </>
        )}
      </IntlContextConsumer>
    </div>
  );
};

export default Language;
