import React from 'react';
import FooterContainer from './FooterContainer';
import FooterCopyright from './FooterCopyright';
import FooterDevelopedBy from './FooterDevelopedBy';
import FooterLinks from './FooterLinks';
import FooterLogo from './FooterLogo';
import FooterSocialMedia from './FooterSocialMedia';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLogo />
      <FooterLinks />
      <FooterSocialMedia />
      <FooterDevelopedBy />
      <FooterCopyright />
    </FooterContainer>
  );
};

export default Footer;
