import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Image from '../Image';

const FooterDevelopedBy = () => {
  const intl = useIntl();
  return (
    <div>
      <p className="text-center mt-8 mb-3 font-medium tracking-wide text-sm text-primary-white">
        {intl.formatMessage({ id: 'footer.developedBY' })}
      </p>
      <div className="flex items-center justify-center md:justify-start">
        <a href="https://www.ployside.com" className="w-full" target="_blank">
          <Image fileName="ployside.png" alt="Ployside Logo"/>
        </a>
      </div>
    </div>
  );
};

export default FooterDevelopedBy;
