import React from 'react';
import { Link } from 'gatsby-plugin-intl';

const NavLink = ({ title, href }) => {
  return (
    <Link
      to={href}
      activeClassName="font-bold"
      className="text-base lg:text-lg leading-6 font-medium text-primary-navy transition ease-in-out duration-150"
    >
      {title}
    </Link>
  );
};

export default NavLink;
