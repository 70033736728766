import React from 'react';
import FooterIcon from '../../images/icons/logo.svg';

const FooterLogo = () => {
  return (
    <div className="flex items-center justify-center md:justify-start">
      <FooterIcon />
    </div>
  );
};

export default FooterLogo;
