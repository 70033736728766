import React from 'react';
import IconMenu from '../../images/icons/menu.svg';

const NavToggler = ({ toggleMobile }) => {
  const openMobile = () => toggleMobile(true);

  return (
    <div className="-mr-2 -my-2 lg:hidden">
      <button
        onClick={openMobile}
        type="button"
        className="inline-flex items-center justify-center p-2 rounded-md bg-primary-navy hover:bg-opacity-80 focus:outline-none transition duration-150 ease-in-out"
      >
        <IconMenu className="h-8 w-8 text-primary-white" />
      </button>
    </div>
  );
};

export default NavToggler;
