import React from 'react';
import Navbar from './Navbar';

const Header = () => (
  <header className="fixed z-10 w-full bg-primary-white">
    <Navbar />
  </header>
);

export default Header;
