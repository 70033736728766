import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill'; //Agregado para ajustar los tamaños de las imágenes al contenedor

export const Image = ({ fileName, alt, ...restProps }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const imagen = data.images.edges.find(image =>
          image.node.relativePath.includes(fileName)
        );

        return fileName.match(/http+s?\u003A?\u002F+/) ? ( //Asegura que se pueda usar solo este componente sin problema, si es una imagen externa o en el localfile
          // HTML native
          <img src={fileName} alt={alt} {...restProps} />
        ) : (
          // Gatsby component
          <Img
            alt={alt}
            fluid={imagen.node.childImageSharp.fluid}
            objectFit="fill"
            {...restProps}
          />
        );
      }}
    />
  );
};

export default Image;
