import React from 'react';
import { Link } from 'gatsby-plugin-intl';

const FooterLink = ({ title, href }) => {
  return (
    <Link
      to={href}
      className="border-b-2 border-primary-navy hover:text-primary-white hover:border-primary-white pb-1 transition duration-300 mt-2 mx-4"
    >
      {title}
    </Link>
  );
};

export default FooterLink;
