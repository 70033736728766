import React from 'react';

const FooterCopyright = () => {
  return (
    <p className="text-center mt-8  font-medium tracking-wide text-sm text-primary-white">
      &copy; Copyright 2021, Dima Painter. All Rights Reserved.
    </p>
  );
};

export default FooterCopyright;
