import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import LogoLink from './LogoLink';
import NavbarMobile from './NavbarMobile/NavbarMobile';
import NavLink from './NavLink';
import NavToggler from './NavToggler';
import Language from './Language';

const Navbar = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const toggleMobile = useCallback(state => setIsMobileOpen(state), []);

  return (
    <div className="px-4 shadow-lg">
      <div className="flex justify-between items-center py-3  md:space-x-10 lg:mx-28">
        <LogoLink />

        <NavToggler toggleMobile={toggleMobile} />

        <nav className="hidden lg:flex space-x-10">
          <NavLink title={<FormattedMessage id="navbar.home" />} href="/" />
          <NavLink
            title={<FormattedMessage id="navbar.services" />}
            href="/services"
          />
          <NavLink
            title={<FormattedMessage id="navbar.about" />}
            href="/about"
          />
          <NavLink
            title={<FormattedMessage id="navbar.contact" />}
            href="/contact"
          />
        </nav>

        <div className="hidden lg:flex justify-self-end">
          <Language />
        </div>
      </div>

      <NavbarMobile isOpen={isMobileOpen} toggleMobile={toggleMobile} />
    </div>
  );
};

export default Navbar;
