import React from 'react';
import { Transition } from '@headlessui/react';
import NavClose from './NavClose';
import Image from '../../Image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import NavLinkMobile from './NavLinkMobile';
import Language from '../Language';

const NavbarMobile = ({ isOpen, toggleMobile }) => {
  return (
    <Transition
      show={isOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      {ref => (
        <div
          ref={ref}
          className="absolute z-10 top-0 inset-x-0 p-2 transition  transform origin-top-right lg:hidden"
        >
          <div className="rounded-lg shadow-lg border border-gray-100">
            <div className="rounded-lg shadow-xs bg-primary-white">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <div className="w-52">
                    <Image
                      fileName="dima-painter-logo.png"
                      alt="Dima Painter Logo"
                      title="logo"
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <Language />
                    <NavClose toggleMobile={toggleMobile} />
                  </div>
                </div>
                <div>
                  <nav className="grid gap-y-8">
                    <NavLinkMobile
                      title={<FormattedMessage id="navbar.home" />}
                      href="/"
                    />
                    <NavLinkMobile
                      title={<FormattedMessage id="navbar.services" />}
                      href="/services"
                    />
                    <NavLinkMobile
                      title={<FormattedMessage id="navbar.about" />}
                      href="/about"
                    />
                    <NavLinkMobile
                      title={<FormattedMessage id="navbar.contact" />}
                      href="/contact"
                    />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default NavbarMobile;
