import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import FooterLink from './FooterLink';

const FooterLinks = () => {
  return (
    <div className="mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row">
      <FooterLink href="/" title={<FormattedMessage id="navbar.home" />} />
      <FooterLink
        href="/services"
        title={<FormattedMessage id="navbar.services" />}
      />
      <FooterLink
        href="/about"
        title={<FormattedMessage id="navbar.about" />}
      />
      <FooterLink
        href="/contact"
        title={<FormattedMessage id="navbar.contact" />}
      />
    </div>
  );
};

export default FooterLinks;
