import React from 'react';

const FooterSocialLink = ({ href, social, children }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={social}
      className="cursor-pointer inline-block text-gray-100 hover:text-gray-300 transition duration-300 mx-4"
    >
      <div className="w-5">{children}</div>
    </a>
  );
};

export default FooterSocialLink;
