import React from 'react';
import IconClose from '../../../images/icons/close.svg';

const NavClose = ({ toggleMobile }) => {
  const closeNavbar = () => {
    toggleMobile(false);
  };

  return (
    <div className="-mr-2">
      <button
        onClick={closeNavbar}
        type="button"
        className="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-100  transition duration-150 ease-in-out"
      >
        <IconClose className="h-8 w-8 text-primary-navy" />
      </button>
    </div>
  );
};

export default NavClose;
