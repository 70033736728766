import React from 'react';

const FooterContainer = ({ children }) => {
  return (
    <footer className="bg-primary-navy text-primary-white">
      <div className="max-w-screen-xl mx-auto py-16">
        <div className="flex items-center justify-center flex-col px-8">
          {children}
        </div>
      </div>
    </footer>
  );
};

export default FooterContainer;
