import React from 'react';
import FooterSocialLink from './FooterSocialLink';
import InstagramIcon from '../../images/icons/instagram.svg';
import TiktokIcon from '../../images/icons/tiktok.svg';

const FooterSocialMedia = () => {
  return (
    <div className="mt-8">
      <FooterSocialLink href="https://www.instagram.com/dimapainter/" social="instagram">
        <InstagramIcon />
      </FooterSocialLink>
      <FooterSocialLink href="https://www.tiktok.com/@dimapainter" social="tiktok">
        <TiktokIcon />
      </FooterSocialLink>
    </div>
  );
};

export default FooterSocialMedia;
