import React from 'react';
import { Link } from 'gatsby-plugin-intl';

const NavLinkMobile = ({ title, href }) => {
  return (
    <Link
      to={href}
      activeClassName="font-bold"
      className="-m-3 p-3 flex text-center font-medium items-center space-x-3 rounded-md text-primary-navy hover:bg-gray-100 focus:outline-none  transition ease-in-out duration-150"
    >
      <span className="text-base w-full text-center leading-6">{title}</span>
    </Link>
  );
};

export default NavLinkMobile;
