import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import Image from '../Image';

const LogoLink = () => {
  return (
    <div className="flex justify-start lg:w-0 lg:flex-1">
      <div className="w-56">
        <Link to="/">
          <Image
            fileName="dima-painter-logo.png"
            alt="Dima Painter Logo"
            title="logo"
          />
        </Link>
      </div>
    </div>
  );
};

export default LogoLink;
